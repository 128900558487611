<template>
  <div id="report-card">
    <b-row>
      <b-col
        cols="12"
        class="text-right pb-2"
      >
        <StatusBarChart
          :status="getStatusCount"
        >
          <b-dropdown
            :text="$t('report.export-import')"
            data-html2canvas-ignore="true"
            variant="outline-primary"
          >
            <b-dropdown-item class=""
                             data-html2canvas-ignore="true"
                             @click="exportExcel()"
            >
              <feather-icon icon="FileMinusIcon" />
              {{ $t('course-completion-module.excel-export') }}
            </b-dropdown-item>
            <b-dropdown-item data-html2canvas-ignore="true"
                             class=""
                             @click="exportPdf()"
            >
              <feather-icon icon="FileTextIcon" />
              {{ $t('report.export-pdf') }}
            </b-dropdown-item>
          </b-dropdown>
        </StatusBarChart>
      </b-col>
    </b-row>
    <b-table
      v-if="calculatedRubric.length > 0"
      :items="calculatedRubric"
      :fields="columns"
      :empty-filtered-text="$t('messages.no-record-found')"
      show-empty
      small
      striped
      responsive
    >
      <template #cell(show_details)="data">
        <b-form-checkbox
          v-model="data.detailsShowing"
          plain
          class="vs-checkbox-con"
          :disabled="data.item.isAnEvent"
          @change="data.toggleDetails"
        >
          <span class="vs-checkbox">
            <span class="vs-checkbox--check">
              <i class="vs-icon feather icon-check" />
            </span>
          </span>
          <span class="vs-label">{{
            data.detailsShowing ? $t("hide") : $t("show")
          }}</span>
        </b-form-checkbox>
      </template>

      <template #cell(title)="data">
        <span v-if="data.item.isAnEvent">{{ data.item.title }}</span>
        <button
          v-else
          class="btn btn-link"
          style="padding: 0.5rem 0"
          @click="
            $emit(
              'navigateToCoursePage',
              data.item.course_id,
            )
          "
        >
          {{ data.item.title }}
        </button>
      </template>
      <template #cell(percentage)="data">
        <span
          :class="{
            'text-success': getPercentage(data) >=100
          }"
        >{{
          getPercentage(data)
        }}%</span>
      </template>
      <template #cell(due)="data">
        <span>{{ data.item.expected - data.item.actual }}</span>
      </template>
      <template #cell(action)="data">
        <div v-if="isATeacher && data.item.isAnEvent && data.item.isEditable">
          <b-button
            :disabled="isProcessing"
            variant="outline-info"
            class="btn-icon"
            size="sm"
            @click.stop="$emit('onEditRubric', data.item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button
            :disabled="isProcessing"
            variant="outline-danger"
            class="btn-icon ml-1"
            size="sm"
            @click.stop="$emit('onDeleteRubric', data.item.id)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </div>
        <div v-if="data.item.isAnEvent && !data.item.isEditable">
          <b-badge variant="dark">
            {{ $t('report.automatic') }}
          </b-badge>
        </div>
      </template>

      <template
        #row-details="data"
      >
        <b-row class="mb-1">
          <b-col cols="6">
            <strong>{{ $t("labels.lesson-name") }} </strong>
          </b-col>
          <b-col cols="2">
            <strong>{{ $t("labels.due-date") }} </strong>
          </b-col>
          <b-col cols="2">
            <strong>{{ $t("labels.status") }} </strong>
          </b-col>
          <b-col
            cols="2"
            class="text-center text-nowrap"
          >
            <strong>{{ $t("labels.completed_on") }} </strong>
          </b-col>
        </b-row>
        <b-row
          v-for="(d, i) in data.item.lesson"
          :key="i"
          class="mb-1"
        >
          <b-col cols="6">
            <span
              role="button"
              @click="$emit('navigateToCoursePageIndividual', d.lesson_id)"
            >

              <feather-icon
                v-if="d.status === 'passed'"
                class="text-success"
                icon="CheckIcon"
              />
              <feather-icon
                v-if="d.status === 'failed'"
                class="text-danger"
                icon="XIcon"
              />
              <small>{{ d.name }}</small>
            </span>
          </b-col>
          <b-col
            cols="2"
            :class="[d.statusSummary?.color]"
          >
            <small v-if="d.is_scorm_lesson && d.schedule_type === 'non-sequenced'"> - </small>
            <small v-else>{{ formatDate(d.end_date) }}</small>
          </b-col>
          <b-col
            cols="2"
            :class="[d.statusSummary?.color]"
          >
            <small v-if="d.is_scorm_lesson && d.schedule_type === 'non-sequenced'"> - </small>
            <small v-else>
              <b-badge
                :variant="d.statusSummary?.variant"
                class="badge-glow"
              >
                {{ $t(`course-completion-module.${ d.statusSummary?.title}`) }}
              </b-badge></small>
          </b-col>
          <b-col
            v-if="d.completed_at"
            cols="2"
            class="text-center text-nowrap"
            :class="[d.statusSummary?.color]"
          >
            <small>{{ formatDate(d.completed_at) }}</small>
          </b-col>
          <b-col
            v-else
            class="text-center"
          >
            <span> - </span>
          </b-col>
        </b-row>
      </template>
    </b-table>
    <p
      v-else
      class="px-1"
    >
      {{ $t("student-diary-module.no-rubrics") }}
    </p>
  </div>
</template>

<script>
import {
  BTable, BButton, BBadge, BFormCheckbox, BRow, BCol, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import i18n from '@/libs/i18n'
import exportFromJSON from 'export-from-json'
import StatusBarChart from './StatusBarChart.vue'

const moment = require('moment')

export default ({
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BTable, BButton, BBadge, BFormCheckbox, BRow, BCol, StatusBarChart, BDropdown, BDropdownItem,
  },
  directives: {
    Ripple,
  },
  props: {
    rubrics: {
      type: Array,
      default: () => [],
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    studentName: {
      type: String,
      default: '',
    },
    isATeacher: {
      type: Boolean,
      default: false,
    },
    noAction: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columns: [
        { key: 'show_details', label: i18n.tc('labels.show-details') },
        { key: 'title', label: i18n.tc('student-diary-module.rubric-title') },
        { key: 'expected', label: i18n.tc('student-diary-module.expected') },
        { key: 'actual', label: i18n.tc('student-diary-module.actual') },
        { key: 'percentage', label: i18n.tc('student-diary-module.percent-complete') },
        { key: 'due', label: i18n.tc('student-diary-module.due') },
        (!this.noAction ? {
          key: 'action', label: i18n.tc('action'), thStyle: { width: '100px' },
        } : {}),
      ],
      self: getUserData(),
    }
  },
  computed: {
    getStatusCount() {
      const json = this.calculatedRubric.map(elem => elem.lesson) // extract the nested array
        .flat() // flatten the array of arrays into a single array
        .reduce((acc, curr) => {
          const status = curr?.statusSummary?.status
          acc[status] = (acc[status] || 0) + 1
          return acc
        }, {})
      const statusArray = Object.keys(json).map(key => ({ status: key, count: json[key] })).filter(item => item.status !== 'undefined')
      return ['Late', 'Not Completed', 'Early', 'On Time'].map(item => statusArray.find(i => i.status === item) || {
        status: item,
        count: 0,
      })
    },
    calculatedRubric() {
      return this.rubrics.map(item => ({
        ...item,
        lesson: item.lesson?.map(i => ({
          ...i,
          statusSummary: this.getLessonColor(i),
        })),
      }))
    },
  },

  methods: {
    getLessonColor(data) {
      const dueDate = data.end_date ? moment(data.end_date).format('YYYY-MM-DD') : null
      const completedDate = data.completed_at ? moment(data.completed_at).format('YYYY-MM-DD') : null
      if (completedDate && dueDate) {
        if (moment(completedDate).isSame(dueDate)) {
          return {
            color: 'text-success',
            status: 'On Time',
            variant: 'success',
            title: 'on-time',
          }
        } if (moment(completedDate).isAfter(dueDate)) {
          return {
            color: 'text-secondary',
            status: 'Late',
            variant: 'secondary',
            title: 'late',
          }
        }
        return {
          color: 'text-success',
          status: 'Early',
          variant: 'primary',
          title: 'early',
        }
      }
      return {
        color: 'text-danger',
        status: 'Not Completed',
        variant: 'danger',
        title: 'not-completed',
      }
    },
    formatDate(date) {
      return moment(date).format('MMM Do YY')
    },
    getPercentage(data) {
      return Number(data.item.expected) !== 0
        ? parseInt((data.item.actual / data.item.expected) * 100, 10)
        : 0
    },
    exportExcel() {
      const data = []

      this.rubrics.forEach(rubric => {
        const newObject = {
          'Rubric Title': rubric.title,
          Expected: rubric.expected ?? 0,
          Actual: rubric.actual ?? 0,
          Completed: `${this.getPercentage({ item: { expected: rubric.expected, actual: rubric.actual } })}%`,
          Due: rubric.due ?? 0,
        }
        // if (rubric.lesson) {
        //   rubric.lesson.forEach(lesson => {
        //     newObject[lesson.name] = { 'Due Data': lesson.end_date ?? '-', Status: lesson.status ?? '-', 'Completed At': lesson.completedDate ?? '-' }
        //   })
        // }

        data.push(newObject)
      })

      // this.rubrics.map(rubric => ({
      //   'Rubric Title': rubric.title,
      //   Expected: rubric.expected ?? 0,
      //   Actual: rubric.actual ?? 0,
      //   Completed: `${this.getPercentage({ item: { expected: rubric.expected, actual: rubric.actual } })}%`,
      //   Due: rubric.due ?? 0,
      // }))

      const fileName = `Rubrics of ${this.studentName}`
      const exportType = 'csv'
      exportFromJSON({ data, fileName, exportType })
    },

    exportPdf() {
      const element = document.getElementById('report-card')
      this.printPdf(element, `Rubrics Of ${this.studentName}.pdf`)
    },
  },

})
</script>
