<template>
  <div
    class="stats w-100"
  >
    <div class="d-flex align-items-center stats-item">
      <span class="stats-title">{{ $t('report.activity') }}</span>
      <div class="w-100">
        <p class="m-0 p-0">
          {{ sectionData.avg_activity }}
        </p>
        <!-- <div
            class="progress w-100"
            style="height: 10px; color: rgb(229, 71, 50)"
          >
            <div
              role="progressbar"
              :style="`background-color:${getStatusColor(
                sectionData.avg_activity
              )};width:${sectionData.avg_activity}%`"
              class="progress-bar"
            />
          </div> -->
      </div>
    </div>
    <div class="d-flex align-items-center stats-item">
      <span class="stats-title">{{ $t('report.attention') }}</span>
      <div
        class="progress w-100"
        style="height: 10px; color: rgb(229, 71, 50)"
      >
        <div
          role="progressbar"
          :style="`background-color:${getStatusColor(
            sectionData.avg_attention
          )};width:${sectionData.avg_attention}%`"
          class="progress-bar"
        />
      </div>
    </div>
    <div
      v-if="showLangLevel"
      class="d-flex align-items-center stats-item level-button-responsive"
    >
      <span class="stats-title">{{ $t('report.level') }}</span>
      <div>
        <!-- size -->
        <b-button-group size="sm">
          <template v-if="distLangLevel">
            <div
              v-for="distlang,distIndex in distLangLevel"
              :key="distIndex"
              type="button"
              class="btn btn-outline-primary"
              :class="{ active: getLevelFromScore(sectionData.cerf_level) === distIndex }"
            >
              {{ distLangLevel[distIndex].label }}
            </div>
          </template>
          <template v-else>
            <div
              v-for="lang, langIndex in cefrLangLevel"
              :key="langIndex"
              type="button"
              class="btn btn-outline-primary"
              :class="{ active: getLevelFromScore(sectionData.cerf_level) === langIndex }"
            >
              {{ lang.label }}
            </div>
          </template>

        </b-button-group>
      </div>
    </div>
    <div class="d-flex align-items-center stats-item">
      <span class="stats-title">{{ $t('report.score') }}</span>
      <p class="m-0 p-0">
        {{ sectionData.avg_score }}%
      </p>
    </div>
  </div>
</template>

<script>
import { getStudentProgressBarColor } from '@/utils/colors'
import { getLevelFromScore, cefrLangLevel } from '@/const/langLevel'
import { BButtonGroup } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { checkSettings } from '@/utils/visibilitySettings'

export default {
  components: {
    BButtonGroup,
  },
  props: {
    sectionData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      cefrLangLevel,
    }
  },
  computed: {
    ...mapGetters('appConfig', ['getSchoolSettings', 'getDistrictSettings']),

    showLangLevel() {
      const distLangLevelSetting = this.getDistrictSettings?.find(metaValue => metaValue.key === 'lang_level') ?? '0'
      // check school level setting
      const schoolLangLevelSetting = this.getSchoolSettings?.find(metaValue => metaValue.key === 'lang_level') ?? '0'
      if (distLangLevelSetting?.value !== '0' && schoolLangLevelSetting?.value !== '0') {
        return false
      }
      return true
    },
    distLangLevel() {
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distLangLevelSetting = checkSettings('lang_level_indicator', schoolSetting, distSetting)?.value
      return JSON.parse(distLangLevelSetting)
    },
  },
  methods: {
    getLevelFromScore(level) {
      return getLevelFromScore(level)
    },
    getStatusColor(value) {
      return getStudentProgressBarColor(value)
    },
  },
}
</script>

<style scoped>
.stats {
  padding: 8px 2px;
}
.stats-title {
  margin-right: 8px;
  font-size: 12px;
  min-width: 60px !important;
}
.btn-group-sm > .btn {
  padding: 4px 10px !important;
}
.stats-item {
  margin: 4px 0;
}
.screenshot-image img{
  width: 100%; height: 100%; object-fit: cover; object-position: 50% 0%;
}

@media(max-width:1200px) and (min-width:768px) {

  .level-button-responsive {
    display: block !important;
  }
  .level-button-responsive .btn-group-sm .btn{
    padding: 4px 6px !important
  }
}
</style>
