import useJwt from '@/auth/jwt/useJwt'
import { ref } from 'vue'

export default function useLessonDashboard() {
  const today = ref(null)
  const studentAssignment = ref([])
  const classRoomAssignment = ref([])
  const hasNoAssignment = ref(false)
  const statisticsData = ref(null)
  const followUpAssignment = ref([])
  const isProcessing = ref(false)

  const getDashboard = () => {
    isProcessing.value = true
    useJwt.getStudentTableData('lesson-dashboard').then(response => {
      today.value = ref(response.data.data.today)
      hasNoAssignment.value = !response.data.data.events.length
      studentAssignment.value = ref(response.data.data.events.filter(i => i.assignment_type === 'private' && i.event_type !== 'follow_up'))
      classRoomAssignment.value = ref(response.data.data.events.filter(i => i.assignment_type === 'public' && i.event_type !== 'follow_up'))
      followUpAssignment.value = ref(response.data.data.events.filter(i => i.event_type === 'follow_up'))
      statisticsData.value = response.data.data.statistics
    }).finally(() => {
      isProcessing.value = false
    })
  }

  return {
    getDashboard,
    isProcessing,
    today,
    studentAssignment,
    classRoomAssignment,
    followUpAssignment,
    hasNoAssignment,
    statisticsData,
  }
}
