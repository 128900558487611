<template>
  <div class="card card-statistics">
    <div class="card-header">
      <h4 class="card-title">
        {{ $t('users-module.statistics') }}
      </h4>
    </div>
    <div class="card-body statistics-body">
      <div class="row">
        <div class="mb-2 mb-md-0 col-sm-6 col-md-3 mb-2 mb-xl-0">
          <div class="media">
            <div class="media-aside mr-2 align-self-start">
              <span
                class="b-avatar badge-light-primary rounded-circle"
                style="width: 48px; height: 48px"
              ><span class="b-avatar-custom">
                <feather-icon icon="TrendingUpIcon" />
              </span>
              </span>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ statisticsData.consecutive_days }}
              </h4>
              <p class="card-text font-small-3 mb-0">
                {{ $t('users-module.consecutive-study-days') }}
              </p>
            </div>
          </div>
        </div>
        <div class="mb-2 mb-md-0 col-sm-6 col-md-3 mb-2 mb-xl-0">
          <div class="media">
            <div class="media-aside mr-2 align-self-start">
              <span
                class="b-avatar badge-light-primary rounded-circle"
                style="width: 48px; height: 48px"
              ><span class="b-avatar-custom">
                <feather-icon icon="ClockIcon" />
              </span>
              </span>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ CONVERT_HM(statisticsData.total_study_time) }}
              </h4>
              <p class="card-text font-small-3 mb-0">
                {{ $t('users-module.total-study-hours') }}
              </p>
            </div>
          </div>
        </div>
        <div class="mb-2 mb-md-0 col-sm-6 col-md-3 mb-2 mb-xl-0">
          <div class="media">
            <div class="media-aside mr-2 align-self-start">
              <span
                class="b-avatar badge-light-primary rounded-circle"
                style="width: 48px; height: 48px"
              ><span class="b-avatar-custom">
                <feather-icon icon="ClockIcon" />
              </span>
              </span>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ CONVERT_HM(statisticsData.average_study_time) }}
              </h4>
              <p class="card-text font-small-3 mb-0">
                {{ $t('users-module.average-study-hours') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    statisticsData: {
      type: Object,
      default: () => null,
    },
  },
  methods: {
    secondToHours(seconds) {
      return seconds / (60 * 60)
    },
  },
}
</script>
