<template>
  <div>
    <b-card
      no-body
      class="card-statistics activity-card"
    >
      <b-card-header>
        <b-card-title>{{ $t('labels.recent-activity') }}</b-card-title>
      </b-card-header>
      <b-card-body
        v-if="noActivity"
        class="text-center mt-2"
      >
        <b-card-text>{{ $t('messages.no-activity') }}</b-card-text>
      </b-card-body>
      <b-card-body v-else>
        <b-row>
          <FsLightbox
            :toggler="toggler"
            :slide="slide"
            :sources="data.map(d => d.screenshot)"
            :types="[...new Array(data.length).fill('image')]"
          />
          <b-col
            v-for="(d, i) in data"
            :key="i"
            cols="12"
            md="6"
          >
            <div class="my-1">
              <b-img
                :src="d.screenshot"
                alt="Meeting Pic"
                class="border screenshot-image"
                @click="() => {toggler = !toggler; slide = i + 1 }"
              />
              <screenshot-card-info :section-data="d" />
            </div>
          </b-col>
        </b-row>
        <div class="text-center mt-2">
          <router-link
            v-if="isAStudent"
            to="/student/my-diary"
          >
            {{ $t('labels.view-all') }}
            <feather-icon icon="ChevronRightIcon" />
          </router-link>
          <router-link
            v-else
            :to="{ name: `${self.usertype}-student-diary`,params: {id: studentID, user: 'student'} }"
          >
            {{ $t('labels.view-all') }}
            <feather-icon icon="ChevronRightIcon" />
          </router-link>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BImg,
  BRow,
  BCol,
  BCardText,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import FsLightbox from 'fslightbox-vue'
import ScreenshotCardInfo from '@/views/common/student-diary/components/ScreenshotCardInfo.vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    FsLightbox,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BImg,
    BRow,
    BCol,
    BCardText,
    FeatherIcon,
    ScreenshotCardInfo,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    toggler: false,
    slide: 1,
    self: getUserData(),
  }),
  computed: {
    noActivity() {
      let status = true
      if (this.data.length) status = false
      return status
    },
    isAStudent() {
      return getUserData().usertype === 'student'
    },
    studentID() {
      return this.$route.params.id
    },
  },
}
</script>

<style lang="scss" scoped>
.activity-card {
  .screenshot-image {
    width: 100%; height: 120px; object-fit: cover; object-position: 50% 0%;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
