<template>
  <div>
    <div v-if="selectedDashboard === 'slp'">
      <LessonDahBoard />
    </div>
    <div v-else>
      <DefaultDashBoard />
    </div>
  </div>
</template>
<script setup>
import { ref, watchEffect } from 'vue'
import store from '@/store'
import DefaultDashBoard from './default/index.vue'
import LessonDahBoard from './lesson-dashboard/index.vue'

const selectedDashboard = ref(null)
// const schoolInfo = JSON.parse(localStorage.getItem('schoolInfo'))
// const districtInfo = JSON.parse(localStorage.getItem('districtInfo'))

watchEffect(() => {
  const schoolSetting = store.state.appConfig?.schoolInfo
  const distSetting = store.state.appConfig?.districtInfo
  let dashboard = schoolSetting?.user_meta?.find(metaValue => metaValue.key === 'student_dashboard')
  if (!dashboard && distSetting) {
    dashboard = distSetting?.user_meta?.find(metaValue => metaValue.key === 'student_dashboard')
  }

  if (dashboard?.value === 'slp') {
    selectedDashboard.value = 'slp'
  } else {
    selectedDashboard.value = 'default'
  }
})
</script>
