const formatRubric = res => {
  const rubricData = res.data.data.events
  const groupKey = 'course_id'
  const groups = rubricData.reduce((rv, x) => {
    // eslint-disable-next-line no-param-reassign
    (rv[x[groupKey]] = rv[x[groupKey]] || []).push(x)
    return rv
  }, {})
  const events = []
  Object.entries(groups).forEach(([key, value]) => {
    const expected = value.length
    const actual = value.filter(v => v.completed_at).length
    const percentComplete = (actual / expected) * 100
    const due = expected - actual
    events.push({
      expected,
      actual,
      isAnEvent: false,
      title: value[0]?.course_name,
      lesson: value,
      percentComplete,
      due,
      course_id: key,
    })
  })

  const rubrics = res.data.data.lists
  // const events = res.data.data.event
  rubrics.forEach(e => {
    e.title = e.rubric.title
    e.isAnEvent = true
    e.isEditable = !e.notEditable
  })
  return {
    rubrics,
    events,
  }
}
export default formatRubric
